import { useQuery } from "@tanstack/vue-query";

export const useUserFairyStore = defineStore("userFairyStore", () => {
  const { userWitch } = storeToRefs(useUserStore());
  const { pocketbase } = storeToRefs(usePocketBase());

  const { data: userFairies } = useQuery({
    queryKey: ["witch_fairies", userWitch],
    queryFn: () =>
      pocketbase.value
        .collection("fairies")
        .getFullList({ filter: `witch.id="${userWitch.value?.id}"` }),
  });

  return {
    userFairies,
  };
});
